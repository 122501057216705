<template>
	<main class="page-container">
		<section class="detail-section" data-fill="true">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">유통ㆍ마케팅 트렌드, 노무ㆍ세무</h3>
				</div>
				<!-- 제목 -->
				<div class="detail-section-header">
					<p class="detail-section-header__sub">[사장님을 위한 솔루션위원회]</p>
					<div class="detail-section-header__title">
						<p class="title">CJ올리브영 MD가 소상공인 뷰티 제품을 애타게 모집합니다!</p>
						<span class="date"></span>
					</div>
				</div>
				<!-- // 제목 -->

				<!-- VOD player -->
				<div class="vod-wrap">
					<iframe name="myplayer" id="myplayer" :src="playerUrl" frameborder="0" width="600" height="400" allow="encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<!-- //VOD player -->

				<!-- VOD 공지 -->
				<div class="detail-section-content">가나다라마바사 아자차카타파하 가나다라마바사 아자차카타파하 가나다라마바사 아자차카타파하 가나다라마바사 아자차카타파하</div>
				<!-- //VOD 공지 -->

				<div class="detail-section-footer">
					<div class="buttons">
						<router-link to="/information/list" class="button-default is-large is-rounded is-gray">이전</router-link>
						<router-link to="/information/list" class="button-default is-large is-rounded is-secondary">목록</router-link>
						<router-link to="/information/list" class="button-default is-large is-rounded is-gray">다음</router-link>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_DELETE_PROG_SMSUSER, ACT_GET_BRC_INFO, ACT_GET_LIVE_VIDEO_LIST, ACT_GET_PROG_SMSUSER_LIST, ACT_INSERT_PROG_SMSUSER, ACT_INSERT_RECOMM_CNT } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { getItems } from '@/assets/js/utils';
const jsonp = require('jsonp');

export default {
	name: 'InfoDetail',
	components: {},
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
	},
	watch: {},
	mounted() {},
	data: () => ({
		playerUrl: 'https://v.kr.kollus.com/s?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtYyI6W3sibWNrZXkiOiJKSDJSNjM1MiIsInNlZWsiOnRydWUsIm1jcGYiOiJzYmRjLXZvZCJ9XSwiZXhwdCI6IjE3MTU1MTkxMTAiLCJ0aXRsZSI6IjAxLzMwIHRlc3QifQ.-BIo1rKVhdV_FFZUZNIwdPtdlIjakLspfbz34Cb4-H0&custom_key=63671086dcc182b0544fc07c39b6265a9e8411d0fe42d45b7c8ce89fc6b74b74&nochat=false',
		res: new Object(),
		msgObj: new Object(),
		brcTit: '',
		soundOn: false, // 소리출력 옵션
		liked: false,
		likeCnt: 0,
		recommCnt: 0,
		timerId: null,
		programList: [],
		vodList: [],
		smsList: [],
		programCd: '',
		programNm: '',
		notice: '',
		relLink: '',
		title: '',
		shareOn: false,
		clientIp: '0.0.0.0',
	}),
	created() {},
	methods: {},
};
</script>
